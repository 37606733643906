import React, { useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import { Button } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import moment from "moment";

import DownloadIcon from "~/components/icons/DownloadIcon";
import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";
import paymentAPI from "~/utils/api/v2/payment";
import { RefundByCancellationResponse } from "~/utils/interfaces/Payments";

function Refunds() {
  const [loading, setLoading] = useState<boolean>(false);
  const [refunds, setRefunds] = useState<RefundByCancellationResponse>([]);
  const [dateInterval, setDateInterval] = useState<moment.Moment[]>([moment().subtract(1, "month"), moment()]);

  const getAndSetRefunds = async () => {
    setLoading(true);
    const { data } = await paymentAPI.getRefundsByCancellation(dateInterval[0], dateInterval[1]);
    setRefunds(data);
    setLoading(false);
  };

  useEffect(() => {
    getAndSetRefunds();
  }, []);

  const getFileLink = async (filename: string) => {
    const { data } = await paymentAPI.getRefundFileLink(filename);
    return data.link;
  };

  const downloadFile = async (filename: string) => {
    const link = await getFileLink(filename);
    window.open(link, "_blank");
  };

  const filterRefunds = async () => {
    setLoading(true);
    const { data } = await paymentAPI.getRefundsByCancellation(dateInterval[0], dateInterval[1]);
    setRefunds(data);
    setLoading(false);
  };

  return (
    <div>
      <h1 className="pb-2">Reembolsos por Cancelaciones</h1>
      <div className="flex items-center py-3">
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale="es"
        >
          <KeyboardDatePicker
            variant="inline"
            format="LL"
            margin="normal"
            label="Fecha Inicio"
            value={dateInterval[0]}
            autoOk
            onChange={(date) => {
              setDateInterval([date as moment.Moment, dateInterval[1]]);
            }}
            className="mx-1"
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale="es"
        >
          <KeyboardDatePicker
            variant="inline"
            format="LL"
            margin="normal"
            label="Fecha Termino"
            value={dateInterval[1]}
            autoOk
            onChange={(date) => {
              setDateInterval([dateInterval[0], date as moment.Moment]);
            }}
            className="mx-3"
          />
        </MuiPickersUtilsProvider>
        <Button
          variant="contained"
          className={clsx(
            "bg-examedi-blue-strong hover:bg-examedi-blue-strong-75 text-white",
            "max-h-10 max-w-24 mx-1",
          )}
          onClick={filterRefunds}
        >
          Filtrar
        </Button>
      </div>
      {loading && <LoadingSpinnerIcon />}
      {!loading && (
        <table>
          <thead className="bg-examedi-blue-lightest">
            <tr>
              {["Fecha Creación", "Periodo", "Planilla Examedi", "Planilla Bemmbo"].map((title) => (
                <th
                  className="py-2 px-3 border text-center"
                  key={title}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {refunds.map((refund) => (
              <tr
                className="bg-white"
                key={refund.created_at}
              >
                <td className="py-2 px-3 border text-center">{moment(refund.created_at).format("DD/MM/YYYY")}</td>
                <td className="py-2 px-3 border flex">
                  <p className="px-2">{moment(refund.created_at).subtract(7, "days").format("DD/MM/YYYY")}</p>-
                  <p className="px-2">{moment(refund.created_at).format("DD/MM/YYYY")}</p>
                </td>
                <td className="py-2 px-3 border cursor-pointer">
                  <DownloadIcon
                    onClick={async () => downloadFile(refund.examedi_file_link)}
                    className="m-auto"
                  />
                </td>
                <td className="py-2 px-3 border cursor-pointer">
                  <DownloadIcon
                    onClick={async () => downloadFile(refund.bemmbo_file_link)}
                    className="m-auto"
                  />
                </td>
              </tr>
            ))}
            {refunds.length === 0 && (
              <tr className="bg-white">
                <td
                  className="py-2 px-3 border text-center"
                  colSpan={4}
                >
                  No hay reembolsos para este periodo
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Refunds;
