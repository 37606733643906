import React from "react";

import clsx from "clsx";

function DownloadIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx("fill-g1", props.className)}
    >
      <path d="M18.0625 7.5H15.8125C15.5031 7.5 15.25 7.75313 15.25 8.0625C15.25 8.37187 15.5031 8.625 15.8125 8.625H18.0625C18.3719 8.625 18.625 8.87813 18.625 9.1875V19.3125C18.625 19.6223 18.3723 19.875 18.0625 19.875H5.6875C5.37777 19.875 5.125 19.6219 5.125 19.3125V9.1875C5.125 8.87813 5.37777 8.625 5.6875 8.625H7.9375C8.24687 8.625 8.5 8.37187 8.5 8.0625C8.5 7.75313 8.24687 7.5 7.9375 7.5H5.6875C4.75691 7.5 4 8.25586 4 9.1875V19.3125C4 20.2441 4.75691 21 5.6875 21H18.0625C18.9931 21 19.75 20.2431 19.75 19.3125V9.1875C19.75 8.25586 18.9941 7.5 18.0625 7.5ZM8.10273 12.1652C7.88301 12.385 7.88301 12.7411 8.10273 12.9605L11.4777 16.3355C11.5867 16.4437 11.7309 16.5 11.875 16.5C12.0191 16.5 12.1629 16.4451 12.2726 16.3352L15.6476 12.9602C15.8673 12.7405 15.8673 12.3843 15.6476 12.165C15.4279 11.9456 15.0718 11.9452 14.8524 12.165L12.4375 14.5805V3.5625C12.4375 3.25158 12.1844 3 11.875 3C11.5656 3 11.3125 3.25158 11.3125 3.5625V14.5805L8.89727 12.1652C8.6793 11.9438 8.3207 11.9438 8.10273 12.1652Z" />
    </svg>
  );
}

export default DownloadIcon;
