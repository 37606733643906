import { AxiosResponse } from "axios";
import moment from "moment";
import qs from "qs";

import api from "~/utils/api/api";
import { RefundByCancellationResponse } from "~/utils/interfaces/Payments";

class PaymentAPI {
  list = async (ids?: string[]): Promise<AxiosResponse<any>> => {
    return api.get(`v2/payments/`, {
      params: { id__in: ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };
  update = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.patch(`v2/payments/${id}/`, data);
  };
  getRefundsByCancellation = async (
    beginDate: moment.Moment,
    endDate: moment.Moment,
  ): Promise<AxiosResponse<RefundByCancellationResponse>> => {
    return api.get(`v2/payments/refunds-by-cancellation/`, {
      params: {
        begin_date: beginDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      },
    });
  };
  getRefundFileLink = async (fileName: string): Promise<AxiosResponse<{ link: string }>> => {
    return api.get(`v2/payments/refunds-file-link/`, {
      params: { file_name: fileName },
    });
  };
}

const paymentAPI = new PaymentAPI();
export default paymentAPI;
